// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-access-to-international-payment-schemes-tsx": () => import("./../../../src/pages/access-to-international-payment-schemes.tsx" /* webpackChunkName: "component---src-pages-access-to-international-payment-schemes-tsx" */),
  "component---src-pages-af-pay-tsx": () => import("./../../../src/pages/af-pay.tsx" /* webpackChunkName: "component---src-pages-af-pay-tsx" */),
  "component---src-pages-aps-guiding-principle-tsx": () => import("./../../../src/pages/aps-guiding-principle.tsx" /* webpackChunkName: "component---src-pages-aps-guiding-principle-tsx" */),
  "component---src-pages-atm-driving-and-monitoring-tsx": () => import("./../../../src/pages/atm-driving-and-monitoring.tsx" /* webpackChunkName: "component---src-pages-atm-driving-and-monitoring-tsx" */),
  "component---src-pages-banks-micro-finance-tsx": () => import("./../../../src/pages/banks-micro-finance.tsx" /* webpackChunkName: "component---src-pages-banks-micro-finance-tsx" */),
  "component---src-pages-board-of-expertise-tsx": () => import("./../../../src/pages/board-of-expertise.tsx" /* webpackChunkName: "component---src-pages-board-of-expertise-tsx" */),
  "component---src-pages-board-of-management-tsx": () => import("./../../../src/pages/board-of-management.tsx" /* webpackChunkName: "component---src-pages-board-of-management-tsx" */),
  "component---src-pages-card-personalization-tsx": () => import("./../../../src/pages/card-personalization.tsx" /* webpackChunkName: "component---src-pages-card-personalization-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-competitive-advantage-tsx": () => import("./../../../src/pages/competitive-advantage.tsx" /* webpackChunkName: "component---src-pages-competitive-advantage-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-corporate-governance-tsx": () => import("./../../../src/pages/corporate-governance.tsx" /* webpackChunkName: "component---src-pages-corporate-governance-tsx" */),
  "component---src-pages-critical-success-factors-tsx": () => import("./../../../src/pages/critical-success-factors.tsx" /* webpackChunkName: "component---src-pages-critical-success-factors-tsx" */),
  "component---src-pages-executive-board-of-dab-tsx": () => import("./../../../src/pages/executive-board-of-dab.tsx" /* webpackChunkName: "component---src-pages-executive-board-of-dab-tsx" */),
  "component---src-pages-fraud-dispute-management-system-tsx": () => import("./../../../src/pages/fraud-dispute-management-system.tsx" /* webpackChunkName: "component---src-pages-fraud-dispute-management-system-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interoperability-tsx": () => import("./../../../src/pages/interoperability.tsx" /* webpackChunkName: "component---src-pages-interoperability-tsx" */),
  "component---src-pages-ivr-banking-tsx": () => import("./../../../src/pages/ivr-banking.tsx" /* webpackChunkName: "component---src-pages-ivr-banking-tsx" */),
  "component---src-pages-key-objective-roles-tsx": () => import("./../../../src/pages/key-objective-roles.tsx" /* webpackChunkName: "component---src-pages-key-objective-roles-tsx" */),
  "component---src-pages-merchant-driving-monitoring-tsx": () => import("./../../../src/pages/merchant-driving-monitoring.tsx" /* webpackChunkName: "component---src-pages-merchant-driving-monitoring-tsx" */),
  "component---src-pages-news-aps-signs-agreement-with-aub-tsx": () => import("./../../../src/pages/news/aps-signs-agreement-with-aub.tsx" /* webpackChunkName: "component---src-pages-news-aps-signs-agreement-with-aub-tsx" */),
  "component---src-pages-news-aps-signs-agreement-with-mb-tsx": () => import("./../../../src/pages/news/aps-signs-agreement-with-mb.tsx" /* webpackChunkName: "component---src-pages-news-aps-signs-agreement-with-mb-tsx" */),
  "component---src-pages-news-bpc-interoperability-in-algeria-tsx": () => import("./../../../src/pages/news/bpc_interoperability_in_algeria.tsx" /* webpackChunkName: "component---src-pages-news-bpc-interoperability-in-algeria-tsx" */),
  "component---src-pages-news-new-development-in-salary-payment-tsx": () => import("./../../../src/pages/news/new-development-in-salary-payment.tsx" /* webpackChunkName: "component---src-pages-news-new-development-in-salary-payment-tsx" */),
  "component---src-pages-news-nkb-completion-tsx": () => import("./../../../src/pages/news/nkb-completion.tsx" /* webpackChunkName: "component---src-pages-news-nkb-completion-tsx" */),
  "component---src-pages-news-second-meeting-cooperation-tsx": () => import("./../../../src/pages/news/second-meeting-cooperation.tsx" /* webpackChunkName: "component---src-pages-news-second-meeting-cooperation-tsx" */),
  "component---src-pages-news-switch-benefits-tsx": () => import("./../../../src/pages/news/switch-benefits.tsx" /* webpackChunkName: "component---src-pages-news-switch-benefits-tsx" */),
  "component---src-pages-news-training-for-banks-at-aps-tsx": () => import("./../../../src/pages/news/training-for-banks-at-aps.tsx" /* webpackChunkName: "component---src-pages-news-training-for-banks-at-aps-tsx" */),
  "component---src-pages-news-training-for-mfis-tsx": () => import("./../../../src/pages/news/training-for-mfis.tsx" /* webpackChunkName: "component---src-pages-news-training-for-mfis-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-news-what-is-national-switch-tsx": () => import("./../../../src/pages/news/what-is-national-switch.tsx" /* webpackChunkName: "component---src-pages-news-what-is-national-switch-tsx" */),
  "component---src-pages-organizational-chart-tsx": () => import("./../../../src/pages/organizational-chart.tsx" /* webpackChunkName: "component---src-pages-organizational-chart-tsx" */),
  "component---src-pages-payment-service-providers-payment-institutions-tsx": () => import("./../../../src/pages/payment-service-providers/payment-institutions.tsx" /* webpackChunkName: "component---src-pages-payment-service-providers-payment-institutions-tsx" */),
  "component---src-pages-policies-and-manuals-tsx": () => import("./../../../src/pages/policies-and-manuals.tsx" /* webpackChunkName: "component---src-pages-policies-and-manuals-tsx" */),
  "component---src-pages-prepaid-card-tsx": () => import("./../../../src/pages/prepaid-card.tsx" /* webpackChunkName: "component---src-pages-prepaid-card-tsx" */),
  "component---src-pages-prepaid-cards-tsx": () => import("./../../../src/pages/prepaid-cards.tsx" /* webpackChunkName: "component---src-pages-prepaid-cards-tsx" */),
  "component---src-pages-public-services-tsx": () => import("./../../../src/pages/public-services.tsx" /* webpackChunkName: "component---src-pages-public-services-tsx" */),
  "component---src-pages-sms-mobile-banking-tsx": () => import("./../../../src/pages/sms-mobile-banking.tsx" /* webpackChunkName: "component---src-pages-sms-mobile-banking-tsx" */),
  "component---src-pages-stakeholder-benefits-tsx": () => import("./../../../src/pages/stakeholder-benefits.tsx" /* webpackChunkName: "component---src-pages-stakeholder-benefits-tsx" */),
  "component---src-pages-transaction-authorization-clearing-settlement-tsx": () => import("./../../../src/pages/transaction-authorization-clearing-settlement.tsx" /* webpackChunkName: "component---src-pages-transaction-authorization-clearing-settlement-tsx" */),
  "component---src-pages-vision-and-mission-tsx": () => import("./../../../src/pages/vision-and-mission.tsx" /* webpackChunkName: "component---src-pages-vision-and-mission-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-events-tsx": () => import("./../../../src/templates/events.tsx" /* webpackChunkName: "component---src-templates-events-tsx" */)
}

